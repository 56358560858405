import React, {useRef} from "react";
import '../styles/main.css';
import '../styles/Contact.css';
import { AiOutlineMail  } from "react-icons/ai";
import emailjs from '@emailjs/browser';

const ContactSection = ()=>{
    const form = useRef();


    const sendEmail = (e) =>{
        e.preventDefault();
        emailjs.sendForm('service_43yb7s7', 'template_a6dwjuo', form.current, '1uFynQhl1OEnbvBx7').then(
            (response) => {
              alert(`E-Mail Sent Success status: ${response.text}`)
              console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                alert(`E-Mail Sent Fail status: ${error}`)
              console.log('FAILED...', error);
            },
          );
          document.getElementById("contact-form").reset();
    }



    return(
        <section id='contact' className="flex-column">
            <div id="content">
            <div id="content-divider">
                <span></span>
            </div>
                <h1>Contact Bleu Willow Advisors</h1>
                <div id="contact-info" className="flex-column">
                    {/* <div id="contact-card" className="flex-column bg-white">

                        <a href="mailto:info@bleuwillowadvisors.com" id='card-content' className="flex align-center contact"> 
                            <AiOutlineMail/>
                           
                            <h3>info@bleuwillowadvisors.com</h3>
                        </a>
                      
                    </div> */}
                    <div>
                        <form id="contact-form" className="flex-column" ref={form} onSubmit={sendEmail}>
                            <div className="flex">

                            
                            <div className="flex-column">
                                <label htmlFor='user_fname'>First Name:</label>
                                <input  required type="text" placeholder="" name="user_fname" />
                            </div>
                            <div className="flex-column">

                            <label htmlFor='user_lname'>Last Name:</label>
                            <input  required type="text" placeholder="" name="user_lname" />
                            </div>
                            </div>
                            <label htmlFor='user_email'>E-MAIL Address:</label>
                            <input  required type="email" placeholder="" name='user_email' />
                            <label htmlFor="phone">Phone Number:</label>

<input type="tel" id="phone" name="phone"  required />

                            <label htmlFor='user_subject'>Subject:</label>
                            <input  required type="text" placeholder="" name="user_subject" />
                            <label htmlFor='user_message'>Message:</label>
                            <textarea  required type="textarea" name="user_message"/>
                            <button className="button-59" type="submit">
                            Send
                                <AiOutlineMail/>
                            </button>
                        </form>
                    </div>
                    {/* <div id="contact-card" className="flex-column bg-navy ">
                    
                            <a href="tel:+15165471100" id="card-content" className="flex align-center contact">

                            
                                <AiFillPhone/>
                                
                                
                                <h3>516-574-1100</h3>
                            </a>
                    
                    </div> */}
                  
                </div>
                
            </div>
        </section>
    )
}
export default ContactSection