import React from "react";
import '../styles/navbar.css'
import '../styles/main.css'
const LogoSVG = () =>{
    return(<svg id="logo-img" version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="100%" viewBox="0 0 2362 2362" enableBackground="new 0 0 2362 2362" xmlSpace="preserve">
   <path fill="#0f2239" opacity="1.000000" stroke="none" 
	   d="
   M1138.000000,2364.000000 
	   C758.666687,2364.000000 380.333344,2364.000000 2.000000,2364.000000 
	   C2.000000,1576.666626 2.000000,789.333313 2.000000,2.000000 
	   C789.333313,2.000000 1576.666626,2.000000 2364.000000,2.000000 
	   C2364.000000,789.333313 2364.000000,1576.666626 2364.000000,2364.000000 
	   C1955.666626,2364.000000 1547.333374,2364.000000 1138.000000,2364.000000 
   M1500.342773,1109.233521 
	   C1502.237671,1110.137329 1504.132446,1111.041016 1505.995605,1112.000000 
	   C1505.995605,1112.000000 1506.052612,1111.972656 1506.350098,1113.245361 
	   C1509.208618,1115.150391 1512.067017,1117.055298 1515.105103,1118.917969 
	   C1515.105103,1118.917969 1515.109863,1119.102417 1515.901489,1119.968872 
	   C1517.258057,1120.664062 1518.614624,1121.359375 1519.995972,1121.994141 
	   C1519.995972,1121.994141 1519.941650,1122.033081 1520.345459,1123.283081 
	   C1522.906982,1124.834717 1525.468506,1126.386475 1528.028687,1128.014771 
	   C1528.028687,1128.014771 1528.081299,1127.957520 1528.659912,1129.068604 
	   C1530.428711,1130.077393 1532.197632,1131.086060 1534.283081,1133.182373 
	   C1534.952271,1133.423096 1535.621338,1133.663818 1536.751587,1135.123413 
	   C1540.147827,1137.747681 1543.544067,1140.372070 1547.107666,1142.904175 
	   C1547.107666,1142.904175 1547.111450,1143.095215 1547.965210,1144.013306 
	   C1551.315552,1146.504883 1554.665771,1148.996460 1558.867920,1152.427856 
	   C1560.579102,1153.618408 1562.290283,1154.808960 1564.363281,1157.289062 
	   C1568.277588,1160.074097 1572.191772,1162.859131 1576.504517,1166.710938 
	   C1577.130493,1167.107178 1577.756348,1167.503540 1577.830444,1169.680786 
	   C1563.713501,1170.659668 1549.559570,1171.284302 1535.493286,1172.749756 
	   C1527.021851,1173.632324 1519.115112,1170.816162 1510.916382,1170.510620 
	   C1499.602051,1170.088867 1488.259033,1170.185181 1476.935669,1170.418701 
	   C1473.393677,1170.491699 1469.770630,1171.415771 1466.393188,1172.564087 
	   C1464.819458,1173.099121 1462.541748,1175.283203 1462.712402,1176.392578 
	   C1462.997559,1178.247314 1464.643555,1180.622925 1466.339355,1181.374268 
	   C1469.559570,1182.801392 1473.242188,1183.153931 1476.692749,1184.098755 
	   C1483.903687,1186.073242 1491.453003,1188.777466 1493.364014,1196.556641 
	   C1494.902222,1202.818848 1494.653442,1209.985718 1493.353760,1216.376099 
	   C1491.100464,1227.454102 1487.585693,1238.291870 1484.303955,1249.140869 
	   C1477.781982,1270.701660 1470.906250,1292.155762 1464.454590,1313.737061 
	   C1454.542236,1346.893677 1445.142334,1380.205566 1435.005127,1413.292236 
	   C1425.650146,1443.825195 1415.564087,1474.133057 1409.659180,1505.609863 
	   C1409.271240,1507.678345 1407.951172,1509.572021 1406.478271,1512.853271 
	   C1404.566162,1510.125122 1403.171753,1508.747925 1402.495728,1507.079834 
	   C1391.512207,1479.977417 1380.707520,1452.802368 1369.683228,1425.716675 
	   C1357.214355,1395.081787 1344.531128,1364.534180 1332.009155,1333.920776 
	   C1323.681763,1313.562256 1315.423584,1293.175049 1307.227295,1272.763306 
	   C1299.908569,1254.537598 1292.735474,1236.253662 1285.463257,1218.009277 
	   C1280.605225,1205.821411 1275.844727,1193.590576 1270.712402,1181.518433 
	   C1268.812622,1177.049927 1266.794067,1172.789917 1260.266235,1172.469360 
	   C1253.376465,1172.130859 1249.610718,1175.563843 1246.703003,1180.599487 
	   C1244.226929,1184.887695 1242.144897,1189.484009 1240.502075,1194.159546 
	   C1232.180176,1217.844360 1224.510986,1241.767700 1215.767700,1265.293213 
	   C1199.813232,1308.222046 1183.192383,1350.902588 1166.992920,1393.740967 
	   C1158.703491,1415.661621 1150.572266,1437.645020 1142.655396,1459.702515 
	   C1136.718384,1476.243408 1131.296753,1492.968750 1125.472534,1509.551147 
	   C1124.811401,1511.432983 1123.029053,1512.921021 1120.715454,1515.982788 
	   C1119.724609,1510.827759 1119.380737,1507.378052 1118.387573,1504.126831 
	   C1107.584839,1468.763672 1096.295898,1433.544189 1085.912109,1398.059448 
	   C1075.666382,1363.046753 1063.530762,1328.607544 1054.915161,1293.089478 
	   C1049.490234,1270.725220 1042.433838,1248.760864 1036.441772,1226.527588 
	   C1034.054688,1217.670166 1032.061646,1208.640259 1030.811279,1199.561890 
	   C1029.637695,1191.040283 1032.089233,1186.114990 1040.860596,1184.337402 
	   C1043.772705,1183.747437 1047.037842,1183.822876 1049.502075,1182.447754 
	   C1051.403564,1181.386841 1053.005127,1178.644043 1053.350098,1176.432373 
	   C1053.516357,1175.366455 1050.686279,1173.268433 1048.855469,1172.553101 
	   C1040.588989,1169.323608 1031.899536,1170.634766 1023.332520,1170.636108 
	   C1014.337280,1170.637573 1005.086609,1169.158447 996.404114,1170.776489 
	   C981.810852,1173.495605 967.533386,1173.588989 953.062378,1170.999756 
	   C950.796570,1170.594360 948.388367,1170.275391 946.142822,1170.595581 
	   C938.559021,1171.677368 932.695984,1168.534912 927.079163,1163.921753 
	   C912.669067,1152.086304 896.724060,1142.865479 879.141663,1136.531128 
	   C876.199585,1135.471069 873.355225,1134.139648 870.643799,1133.007935 
	   C870.916138,1131.567627 870.809631,1130.681641 871.188477,1130.349854 
	   C875.172058,1126.861084 879.295105,1123.531006 883.262512,1120.024658 
	   C905.686646,1100.206665 924.190063,1077.841309 928.725037,1046.986694 
	   C930.507324,1034.860840 933.255127,1023.093262 930.532410,1010.216370 
	   C924.843750,983.312561 910.515625,963.155884 887.432861,949.335388 
	   C864.271484,935.467712 838.001343,932.585449 811.731689,930.701965 
	   C803.114563,930.084167 794.404663,930.399414 785.752502,930.711426 
	   C771.259155,931.234070 756.784973,932.385620 742.289856,932.730835 
	   C728.985657,933.047852 715.509277,933.859863 702.400330,932.179382 
	   C692.111572,930.860413 681.969421,930.625793 671.716187,930.519531 
	   C666.402649,930.464478 661.034363,929.628906 655.794189,930.162048 
	   C649.833130,930.768555 643.158691,928.641296 638.051392,933.405762 
	   C639.009705,941.020508 640.548706,943.011230 646.218506,943.947266 
	   C649.796570,944.538025 653.429688,944.809021 656.995239,945.458618 
	   C670.214355,947.867188 680.686218,953.623962 683.327087,968.203003 
	   C684.136292,972.670471 684.311829,977.252747 684.346680,983.263794 
	   C684.528137,984.260986 684.709656,985.258240 684.591125,987.927429 
	   C684.598572,991.890564 684.605957,995.853699 684.076050,1001.647156 
	   C684.046204,1097.178589 684.013000,1192.709961 684.009644,1288.241333 
	   C684.009521,1290.217407 684.353882,1292.193359 684.381042,1295.984985 
	   C684.291992,1303.325806 684.202942,1310.666748 682.750183,1318.531006 
	   C682.674072,1320.408813 682.597900,1322.286743 682.323669,1325.769409 
	   C682.390442,1328.460083 682.457214,1331.150635 682.049805,1335.536377 
	   C681.580688,1341.092163 681.111511,1346.647949 680.350708,1353.396851 
	   C680.414429,1354.210327 680.478210,1355.023682 680.069824,1357.267456 
	   C680.248779,1358.239746 680.427734,1359.211914 680.381836,1361.455811 
	   C680.183899,1362.290039 679.986023,1363.124390 678.440552,1364.513794 
	   C678.502075,1366.408325 678.563599,1368.302856 678.124573,1371.439087 
	   C675.735229,1374.580566 673.345886,1377.722046 669.975220,1380.974854 
	   C669.270813,1381.601440 668.566345,1382.228027 666.526917,1383.533691 
	   C665.073975,1383.895874 663.621094,1384.258179 660.914307,1384.118408 
	   C659.281982,1384.774902 657.649597,1385.431396 654.702271,1385.807373 
	   C653.823914,1385.929443 652.945496,1386.051636 650.660767,1386.043213 
	   C648.264221,1387.642334 645.480103,1388.876099 643.554626,1390.919189 
	   C639.925232,1394.770386 640.551270,1397.992920 645.245789,1400.046509 
	   C647.850037,1401.185913 650.897156,1402.049683 653.678894,1401.890137 
	   C659.124634,1401.578247 664.524170,1400.462280 671.755066,1399.590820 
	   C677.758179,1399.461914 683.761292,1399.333008 691.356018,1399.678101 
	   C692.989685,1399.510254 694.623291,1399.342407 697.414856,1399.438354 
	   C698.200073,1399.368774 698.985229,1399.299194 701.214233,1399.772339 
	   C702.217834,1399.618652 703.221497,1399.464844 706.054016,1399.558105 
	   C717.953857,1399.425903 729.853638,1399.293701 743.326233,1399.675171 
	   C744.961670,1399.539551 746.597168,1399.403931 749.859741,1399.512207 
	   C752.601379,1399.470825 755.343018,1399.429443 759.380920,1400.289795 
	   C763.653076,1400.657349 767.925171,1401.024902 773.843933,1401.578857 
	   C777.150513,1401.450195 780.457092,1401.321533 785.158569,1401.733765 
	   C786.148621,1401.710571 787.138611,1401.687500 789.710266,1402.182495 
	   C792.475098,1402.102173 795.239868,1402.021729 799.510132,1402.520996 
	   C810.337097,1402.325195 821.164124,1402.129395 833.766357,1402.222534 
	   C851.930176,1401.171387 869.708008,1397.437500 886.705811,1391.445068 
	   C914.333923,1381.705078 938.146057,1365.897827 955.909790,1342.039673 
	   C963.201111,1332.246826 969.950439,1322.153198 971.803833,1309.616577 
	   C972.027588,1308.103027 973.793335,1306.817505 975.587097,1304.443237 
	   C977.068115,1309.429321 978.097900,1313.193604 979.301880,1316.901489 
	   C987.530212,1342.244141 995.690796,1367.609497 1004.080322,1392.898804 
	   C1011.195190,1414.345703 1018.736816,1435.651489 1025.820435,1457.108398 
	   C1034.919556,1484.669800 1043.674072,1512.344727 1052.717529,1539.924683 
	   C1061.017090,1565.236572 1069.488647,1590.492188 1077.914917,1615.762451 
	   C1080.703003,1624.123901 1083.201782,1632.610107 1086.520264,1640.759521 
	   C1090.921387,1651.567993 1100.936035,1652.885742 1108.229858,1643.700195 
	   C1110.850952,1640.399414 1112.728394,1636.393311 1114.407104,1632.478271 
	   C1123.293701,1611.752930 1132.257568,1591.054688 1140.728516,1570.158203 
	   C1155.792969,1532.996582 1170.776978,1495.798340 1185.315430,1458.428833 
	   C1197.518433,1427.062256 1209.048706,1395.433838 1220.856567,1363.913818 
	   C1225.940186,1350.343750 1231.074707,1336.791138 1235.970825,1323.153442 
	   C1237.583496,1318.661011 1238.519775,1313.925781 1240.190674,1307.721924 
	   C1241.651978,1310.411865 1242.332397,1311.427734 1242.787109,1312.536255 
	   C1252.500244,1336.212891 1262.165649,1359.909302 1271.886963,1383.582642 
	   C1282.723755,1409.972900 1293.530151,1436.375854 1304.493652,1462.713623 
	   C1313.481201,1484.304199 1322.879028,1505.725220 1331.736450,1527.368286 
	   C1344.423828,1558.369507 1356.692627,1589.542114 1369.350220,1620.555664 
	   C1372.448975,1628.147827 1375.684937,1635.838745 1380.061768,1642.718140 
	   C1386.636230,1653.052246 1395.068848,1652.914062 1400.077759,1641.970581 
	   C1406.740723,1627.413330 1412.448853,1612.341431 1417.476318,1597.130127 
	   C1426.205811,1570.718506 1433.901367,1543.966919 1442.402832,1517.477295 
	   C1449.770996,1494.519287 1457.588623,1471.704224 1465.326172,1448.866089 
	   C1479.708740,1406.414307 1494.106323,1363.967285 1508.640503,1321.567139 
	   C1516.213989,1299.472900 1523.894043,1277.410889 1531.893433,1255.468262 
	   C1538.425903,1237.549438 1545.391602,1219.785278 1552.388794,1202.040527 
	   C1555.724487,1193.581299 1561.931519,1187.322388 1570.578003,1184.837402 
	   C1577.457031,1182.860596 1584.873779,1182.754395 1592.641846,1182.990601 
	   C1595.106079,1184.656494 1597.570435,1186.322388 1600.349121,1189.309570 
	   C1604.943359,1194.055054 1609.537598,1198.800537 1614.520264,1204.727539 
	   C1615.738892,1205.799194 1616.957397,1206.870728 1618.424683,1209.260864 
	   C1623.251709,1214.473999 1628.078735,1219.687012 1633.091187,1224.910645 
	   C1633.091187,1224.910645 1633.104004,1225.095825 1633.935181,1226.014160 
	   C1635.918213,1228.069702 1637.901245,1230.125122 1639.990234,1232.045288 
	   C1639.990234,1232.045288 1639.827271,1232.099976 1639.957764,1233.280151 
	   C1640.952881,1234.491699 1641.947876,1235.703247 1643.355103,1236.715576 
	   C1643.355103,1236.715576 1643.029663,1237.037598 1643.517334,1238.152222 
	   C1644.980591,1239.517456 1646.443848,1240.882690 1647.899414,1243.190063 
	   C1648.112793,1243.887207 1648.583984,1244.243164 1649.569580,1245.568481 
	   C1651.359985,1248.015503 1653.150269,1250.462524 1655.391846,1252.773926 
	   C1655.391846,1252.773926 1655.004028,1253.041382 1655.528931,1254.291504 
	   C1661.738525,1263.402588 1667.948242,1272.513794 1674.093384,1281.849731 
	   C1674.093384,1281.849731 1674.326660,1281.868042 1674.381470,1283.329590 
	   C1687.302856,1307.438354 1697.284912,1332.778442 1700.061157,1360.019043 
	   C1702.466187,1383.616699 1700.041138,1407.100342 1689.099854,1428.915894 
	   C1686.609619,1433.881104 1684.436768,1439.005371 1682.004761,1444.005127 
	   C1682.004761,1444.005127 1682.058472,1444.117188 1680.765991,1444.496338 
	   C1675.899048,1451.015747 1671.031982,1457.535156 1664.947632,1464.541992 
	   C1662.635010,1467.146606 1660.322266,1469.751221 1656.694580,1472.470337 
	   C1652.463867,1476.313843 1648.233032,1480.157349 1644.000000,1484.000000 
	   C1644.000000,1484.000000 1644.000977,1484.001953 1642.603760,1484.148804 
	   C1629.750854,1492.138672 1616.897949,1500.128418 1602.834106,1508.013184 
	   C1601.896240,1508.696167 1600.958374,1509.379150 1598.573975,1510.134277 
	   C1594.380249,1512.083374 1590.186401,1514.032471 1584.670288,1515.948242 
	   C1583.116089,1516.766846 1581.561890,1517.585449 1578.484009,1518.125366 
	   C1565.635498,1521.948730 1552.773926,1525.729126 1539.957153,1529.656006 
	   C1538.537109,1530.091309 1537.373169,1531.362427 1534.490112,1532.411011 
	   C1520.999756,1534.947998 1507.509521,1537.485107 1492.258789,1539.752686 
	   C1484.758667,1540.752930 1477.258667,1541.753296 1469.239014,1542.822876 
	   C1470.273560,1543.974731 1470.676880,1544.790894 1471.039185,1544.773071 
	   C1478.818359,1544.390015 1486.593018,1543.913452 1495.985474,1543.487793 
	   C1506.510620,1542.362305 1517.023193,1541.098755 1527.563110,1540.134766 
	   C1578.753784,1535.453125 1628.442261,1524.678833 1675.536743,1503.531250 
	   C1703.492065,1490.978027 1728.487915,1473.969360 1748.363281,1450.550049 
	   C1775.788452,1418.235107 1785.169800,1380.422241 1778.811401,1338.780640 
	   C1773.172729,1301.853394 1756.859741,1269.270874 1736.148926,1238.729858 
	   C1711.236084,1201.992554 1681.029907,1170.047363 1647.930908,1140.536621 
	   C1617.637207,1113.526855 1585.484131,1089.067993 1551.912964,1066.402710 
	   C1498.066528,1030.048828 1441.300903,998.895081 1382.543457,971.219360 
	   C1333.776855,948.249390 1283.909058,928.013123 1233.013184,910.394836 
	   C1160.772461,885.387634 1087.169189,865.388367 1011.958130,851.278381 
	   C955.852478,840.752625 899.332214,833.738647 842.397156,830.808105 
	   C816.581055,829.479370 790.535156,830.436279 764.705078,832.094299 
	   C730.230469,834.307251 696.113281,839.796448 663.088806,850.359314 
	   C628.900269,861.294434 596.934998,876.205078 571.722473,903.008362 
	   C552.640625,923.294006 541.461792,947.221130 536.902039,974.445740 
	   C536.553589,976.526306 537.379089,978.803528 537.792847,982.035645 
	   C540.123230,980.007446 541.741516,978.926453 542.949646,977.498352 
	   C560.376404,956.899536 582.194031,942.232666 606.577576,931.389954 
	   C620.117004,925.369385 634.141418,920.381897 648.125366,915.432007 
	   C670.574097,907.485901 693.864075,902.919800 717.381348,899.622742 
	   C730.432800,897.792908 743.572937,896.510193 756.707397,895.380188 
	   C771.561890,894.102295 786.440979,892.834167 801.334717,892.362183 
	   C818.311157,891.824341 835.315552,892.155823 852.307800,892.144714 
	   C855.300537,892.142761 858.297607,892.242126 861.285278,892.419861 
	   C869.526001,892.910034 877.762756,893.466492 886.953003,895.061951 
	   C889.308777,895.102661 891.664551,895.143433 895.753418,895.537048 
	   C899.843811,895.666382 903.934265,895.795776 909.299377,896.421631 
	   C911.538818,896.706970 913.778320,896.992310 917.644287,897.902161 
	   C921.107727,897.899658 924.571106,897.897156 929.153748,898.517090 
	   C930.776733,898.716187 932.399658,898.915344 935.650085,899.534790 
	   C938.418579,899.732239 941.187012,899.929688 944.830261,901.110168 
	   C946.560486,901.132629 948.290649,901.155029 951.622864,901.857910 
	   C958.407104,902.595337 965.191345,903.332703 972.799133,905.116211 
	   C974.540771,905.096924 976.282410,905.077637 979.652405,905.652039 
	   C985.732666,906.532288 991.812927,907.412598 998.574219,909.248291 
	   C999.723267,909.213989 1000.872314,909.179749 1003.542236,909.799805 
	   C1005.718262,909.792725 1007.894226,909.785645 1011.072693,910.399048 
	   C1012.050354,910.829285 1013.028015,911.259460 1015.314270,912.149841 
	   C1016.227783,912.043640 1017.141235,911.937500 1019.118958,912.563538 
	   C1020.752991,912.764526 1022.386963,912.965576 1025.416870,913.797302 
	   C1026.840576,914.246704 1028.264282,914.696167 1030.725464,915.528809 
	   C1031.820068,915.533936 1032.914795,915.539001 1035.414917,916.153320 
	   C1036.942261,916.105408 1038.469482,916.057495 1040.975952,917.052429 
	   C1044.655762,917.822937 1048.335449,918.593506 1053.398560,920.033447 
	   C1054.930786,920.027039 1056.462891,920.020691 1058.926270,921.090515 
	   C1062.622925,921.836487 1066.319702,922.582458 1071.253418,924.011047 
	   C1072.185547,923.955811 1073.117676,923.900574 1075.248413,924.731018 
	   C1086.835815,927.655579 1098.423340,930.580078 1111.241455,934.155884 
	   C1112.165771,934.089294 1113.090210,934.022705 1114.813843,935.075073 
	   C1117.881104,935.831665 1120.948486,936.588257 1125.224243,938.025818 
	   C1126.145752,938.028381 1127.067383,938.030945 1128.900879,939.181396 
	   C1146.286255,944.523132 1163.681030,949.834778 1181.053711,955.217590 
	   C1192.055176,958.626221 1203.023804,962.140930 1215.163208,966.236633 
	   C1216.781494,966.806946 1218.399658,967.377197 1220.835815,969.173828 
	   C1223.920044,970.130249 1227.040039,970.985596 1230.082031,972.061340 
	   C1240.739624,975.830322 1251.370117,979.675232 1263.004395,984.153870 
	   C1263.791138,984.184082 1264.577881,984.214294 1265.985596,985.557312 
	   C1276.649902,989.725769 1287.314087,993.894165 1298.548950,999.148804 
	   C1299.700806,999.428284 1300.852539,999.707764 1302.777222,1001.156311 
	   C1314.108643,1006.143799 1325.457764,1011.091248 1336.765991,1016.131042 
	   C1346.540039,1020.487122 1356.267334,1024.948242 1366.845337,1029.963989 
	   C1367.185669,1029.992310 1367.526123,1030.020630 1368.422241,1031.317871 
	   C1384.938110,1040.266724 1401.437378,1049.246460 1417.976807,1058.151733 
	   C1426.627319,1062.809448 1435.348389,1067.335938 1444.025635,1072.015991 
	   C1444.025635,1072.015991 1444.095215,1071.950928 1444.603516,1073.200684 
	   C1447.755737,1074.756958 1450.907959,1076.313354 1454.074219,1078.005981 
	   C1454.074219,1078.005981 1454.175415,1077.913574 1454.776733,1079.112061 
	   C1463.169312,1084.102295 1471.562012,1089.092651 1480.012085,1094.016235 
	   C1480.012085,1094.016235 1479.928345,1094.040405 1480.239380,1095.374634 
	   C1484.852173,1098.192627 1489.465088,1101.010620 1494.048340,1103.978638 
	   C1494.048340,1103.978638 1494.186401,1103.912842 1494.573853,1105.098511 
	   C1496.379028,1106.072388 1498.184326,1107.046143 1500.000000,1108.000000 
	   C1500.000000,1108.000000 1499.980469,1108.010742 1500.342773,1109.233521 
   M1602.417603,1548.012085 
	   C1600.961426,1548.025513 1599.505249,1548.039062 1596.357910,1548.011841 
	   C1592.517456,1548.618530 1588.684204,1549.276001 1584.834473,1549.817017 
	   C1581.163330,1550.333008 1576.948120,1547.426758 1572.545776,1551.442017 
	   C1582.037476,1551.442017 1590.213013,1551.442017 1599.686890,1551.450684 
	   C1600.985474,1551.437256 1602.283936,1551.423828 1605.097900,1552.007080 
	   C1606.159668,1551.877930 1607.221436,1551.748779 1610.020142,1551.672363 
	   C1615.217896,1551.602173 1620.415771,1551.531982 1627.311646,1552.092407 
	   C1632.877197,1551.426025 1638.442871,1550.759644 1645.763916,1550.121338 
	   C1650.405029,1549.939087 1655.046021,1549.756714 1661.055542,1550.104126 
	   C1662.702637,1549.244507 1664.349609,1548.384766 1667.580322,1547.312988 
	   C1670.235718,1547.322144 1672.891235,1547.331299 1676.997925,1548.050415 
	   C1680.000244,1547.404907 1683.002563,1546.759521 1687.700562,1546.160889 
	   C1699.017090,1544.135620 1710.384399,1542.355835 1721.638184,1540.027100 
	   C1737.971924,1536.647217 1754.544678,1533.911499 1770.432007,1529.009888 
	   C1792.030518,1522.346191 1813.085205,1513.992920 1833.137207,1503.254883 
	   C1858.838379,1489.491699 1881.149414,1471.937256 1898.524414,1448.284424 
	   C1908.772827,1434.333252 1916.815674,1419.152588 1921.531860,1402.698486 
	   C1925.109985,1390.214478 1926.369507,1377.126099 1926.419434,1363.820312 
	   C1926.479126,1347.901489 1924.930786,1332.264282 1921.664185,1316.891235 
	   C1916.663208,1293.354980 1906.265259,1271.669189 1895.460571,1250.378052 
	   C1882.547852,1224.932129 1865.623291,1201.976440 1847.997559,1179.633057 
	   C1830.074463,1156.912964 1810.442261,1135.741943 1789.605347,1115.591064 
	   C1752.517822,1079.724854 1712.720581,1047.201782 1670.506470,1017.672485 
	   C1641.787598,997.583374 1612.837158,977.869385 1582.604248,959.947571 
	   C1547.643311,939.223083 1512.278320,919.328430 1476.047974,900.988098 
	   C1452.898682,889.269531 1429.487427,878.008118 1405.789185,867.449829 
	   C1376.903442,854.580505 1347.756226,842.257507 1318.409180,830.478333 
	   C1298.873657,822.637268 1278.837158,816.025879 1258.940063,809.108093 
	   C1236.020020,801.139160 1213.175903,792.887390 1189.989746,785.764343 
	   C1162.373657,777.280518 1134.535522,769.460510 1106.593262,762.115295 
	   C1084.411133,756.284302 1062.016968,751.164978 1039.551880,746.529968 
	   C1016.740601,741.823547 993.761292,737.893372 970.788391,734.022217 
	   C957.827820,731.838196 944.737000,730.418274 931.695496,728.726318 
	   C914.556030,726.502686 897.435730,724.081970 880.252747,722.259766 
	   C868.438843,721.007019 856.540466,720.452942 844.665283,719.898804 
	   C827.468750,719.096436 810.256592,718.001465 793.055542,718.075134 
	   C769.205811,718.177307 745.354126,719.083862 721.515442,719.971924 
	   C709.290405,720.427429 696.981873,720.841858 684.907532,722.613586 
	   C667.694946,725.139099 650.669556,728.930664 633.552185,732.116943 
	   C604.311340,737.560059 576.323364,747.077209 549.426086,759.479492 
	   C521.250305,772.471252 496.090881,789.935547 474.801270,814.440979 
	   C472.953003,817.276489 471.104736,820.111938 469.096954,823.125671 
	   C469.096954,823.125671 468.913422,823.279114 467.854034,824.039795 
	   C456.088226,841.091309 448.376526,859.845764 443.823486,881.545410 
	   C443.888367,883.029968 443.953217,884.514465 443.426147,887.154297 
	   C443.043243,888.809387 442.660309,890.464539 441.788940,893.914978 
	   C441.951721,903.896606 442.114502,913.878174 442.121033,925.520691 
	   C443.694427,931.759338 445.267822,937.997986 446.517700,945.411438 
	   C446.541321,946.218262 446.564911,947.025024 446.187225,949.366516 
	   C447.502472,953.012695 449.185822,956.571716 450.073792,960.319153 
	   C456.335114,986.743164 467.641663,1010.970764 481.598572,1034.106079 
	   C498.081360,1061.428101 517.434448,1086.647339 537.901978,1111.031128 
	   C546.417358,1121.175903 555.372375,1130.963379 564.400513,1140.659180 
	   C567.375427,1143.854126 570.502197,1147.970337 576.123047,1145.856079 
	   C575.205994,1140.466919 556.791260,1114.424194 541.794922,1096.391846 
	   C531.840942,1080.193970 520.056274,1064.816406 512.347229,1047.612549 
	   C499.694153,1019.375671 492.224945,989.571777 495.039886,957.919617 
	   C496.279846,943.976990 498.826233,930.447266 503.901093,917.522034 
	   C516.483215,885.476501 539.121338,861.628052 567.549988,843.336487 
	   C602.837952,820.631653 642.171997,807.703796 683.143738,800.370789 
	   C709.740417,795.610596 736.767334,793.186951 763.646851,790.112488 
	   C772.813049,789.064026 782.133911,788.664673 791.355530,788.936707 
	   C817.482788,789.707520 843.649231,788.043823 869.755859,790.955566 
	   C887.433411,792.927124 905.329895,792.998535 922.978394,795.150391 
	   C946.842590,798.059998 970.679626,801.518921 994.316040,805.889954 
	   C1025.191772,811.599548 1055.973877,817.935181 1086.578613,824.950439 
	   C1108.114746,829.886963 1129.442017,835.899536 1150.587280,842.335876 
	   C1181.374268,851.706848 1212.136108,861.255676 1242.503052,871.887146 
	   C1282.108521,885.753052 1320.956299,901.576782 1359.316650,918.698303 
	   C1395.064697,934.653748 1430.136475,951.890015 1464.605225,970.359009 
	   C1487.926025,982.854736 1511.026245,995.812561 1533.728394,1009.394287 
	   C1548.699707,1018.350891 1562.721191,1028.884155 1577.285034,1038.535156 
	   C1617.804321,1065.386108 1656.227173,1094.904541 1692.139160,1127.704468 
	   C1721.172241,1154.221680 1748.192261,1182.525879 1772.365845,1213.612549 
	   C1792.428101,1239.412109 1809.469360,1267.062622 1821.695190,1297.196045 
	   C1833.288574,1325.770630 1840.502930,1355.407837 1837.010986,1387.126465 
	   C1834.049561,1414.026489 1824.994507,1438.239258 1808.413696,1458.952759 
	   C1790.071411,1481.866821 1766.671753,1499.229248 1739.990967,1511.750977 
	   C1718.647339,1521.767822 1696.732178,1530.463623 1673.600220,1535.334473 
	   C1653.901855,1539.482422 1633.995117,1542.641113 1613.004883,1546.062744 
	   C1611.873779,1546.294189 1610.742554,1546.525635 1608.337036,1546.902100 
	   C1607.939575,1547.059937 1607.542114,1547.217651 1605.996582,1547.244019 
	   C1605.288940,1547.542114 1604.581299,1547.840210 1602.417603,1548.012085 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M741.753479,1399.161621 
	   C729.853638,1399.293701 717.953857,1399.425903 705.170776,1398.848877 
	   C701.977295,1395.168213 700.649353,1396.268433 699.770447,1399.229614 
	   C698.985229,1399.299194 698.200073,1399.368774 696.744934,1398.710205 
	   C693.476318,1395.832520 691.175415,1395.220825 689.764404,1399.204224 
	   C683.761292,1399.333008 677.758179,1399.461914 670.196899,1399.163330 
	   C665.375793,1398.485596 662.099792,1398.344727 658.853638,1397.955322 
	   C655.403381,1397.541382 650.766052,1398.088501 648.802551,1396.093994 
	   C644.836609,1392.065430 652.310730,1390.054688 652.067139,1386.173828 
	   C652.945496,1386.051636 653.823914,1385.929443 655.746826,1386.436646 
	   C659.545349,1388.365234 661.708496,1388.365479 662.168152,1384.620361 
	   C663.621094,1384.258179 665.073975,1383.895874 667.355713,1383.894043 
	   C671.612122,1385.179932 671.671570,1385.107178 670.956482,1380.863525 
	   C673.345886,1377.722046 675.735229,1374.580566 679.041138,1370.781982 
	   C682.591797,1367.995483 682.620850,1365.937744 679.788086,1363.958618 
	   C679.986023,1363.124390 680.183899,1362.290039 681.064209,1360.766113 
	   C684.392639,1357.797363 684.101379,1356.352905 680.541931,1355.837158 
	   C680.478210,1355.023682 680.414429,1354.210327 681.153259,1352.821045 
	   C683.068359,1351.303345 685.131592,1350.364136 685.134338,1349.418945 
	   C685.148132,1344.445190 684.668518,1339.467651 684.241577,1334.501587 
	   C684.218628,1334.234375 683.121216,1334.059326 682.523987,1333.841309 
	   C682.457214,1331.150635 682.390442,1328.460083 683.098022,1324.937622 
	   C685.067810,1322.921875 686.263245,1321.737915 687.458618,1320.553955 
	   C686.343750,1319.705078 685.228821,1318.856323 684.113953,1318.007568 
	   C684.202942,1310.666748 684.291992,1303.325806 685.269531,1294.974487 
	   C689.997803,1287.473267 687.812134,1280.540283 687.757751,1272.845947 
	   C687.899048,1197.023560 687.931763,1122.110596 687.937317,1047.197510 
	   C687.937866,1040.209839 687.766724,1033.222290 687.816895,1025.305420 
	   C687.973267,1017.856201 688.220642,1011.322998 687.847473,1004.825378 
	   C687.748352,1003.099609 685.743286,1001.483398 684.613403,999.816833 
	   C684.605957,995.853699 684.598572,991.890564 685.282288,987.065125 
	   C688.044250,984.061279 687.986694,982.495483 684.777527,981.782410 
	   C684.311829,977.252747 684.136292,972.670471 683.327087,968.203003 
	   C680.686218,953.623962 670.214355,947.867188 656.995239,945.458618 
	   C653.429688,944.809021 649.796570,944.538025 646.218506,943.947266 
	   C640.548706,943.011230 639.009705,941.020508 638.051392,933.405762 
	   C643.158691,928.641296 649.833130,930.768555 655.794189,930.162048 
	   C661.034363,929.628906 666.402649,930.464478 671.716187,930.519531 
	   C681.969421,930.625793 692.111572,930.860413 702.400330,932.179382 
	   C715.509277,933.859863 728.985657,933.047852 742.289856,932.730835 
	   C756.784973,932.385620 771.259155,931.234070 785.752502,930.711426 
	   C794.404663,930.399414 803.114563,930.084167 811.731689,930.701965 
	   C838.001343,932.585449 864.271484,935.467712 887.432861,949.335388 
	   C910.515625,963.155884 924.843750,983.312561 930.532410,1010.216370 
	   C933.255127,1023.093262 930.507324,1034.860840 928.725037,1046.986694 
	   C924.190063,1077.841309 905.686646,1100.206665 883.262512,1120.024658 
	   C879.295105,1123.531006 875.172058,1126.861084 871.188477,1130.349854 
	   C870.809631,1130.681641 870.916138,1131.567627 870.643799,1133.007935 
	   C873.355225,1134.139648 876.199585,1135.471069 879.141663,1136.531128 
	   C896.724060,1142.865479 912.669067,1152.086304 927.079163,1163.921753 
	   C932.695984,1168.534912 938.559021,1171.677368 946.142822,1170.595581 
	   C948.388367,1170.275391 950.796570,1170.594360 953.062378,1170.999756 
	   C967.533386,1173.588989 981.810852,1173.495605 996.404114,1170.776489 
	   C1005.086609,1169.158447 1014.337280,1170.637573 1023.332520,1170.636108 
	   C1031.899536,1170.634766 1040.588989,1169.323608 1048.855469,1172.553101 
	   C1050.686279,1173.268433 1053.516357,1175.366455 1053.350098,1176.432373 
	   C1053.005127,1178.644043 1051.403564,1181.386841 1049.502075,1182.447754 
	   C1047.037842,1183.822876 1043.772705,1183.747437 1040.860596,1184.337402 
	   C1032.089233,1186.114990 1029.637695,1191.040283 1030.811279,1199.561890 
	   C1032.061646,1208.640259 1034.054688,1217.670166 1036.441772,1226.527588 
	   C1042.433838,1248.760864 1049.490234,1270.725220 1054.915161,1293.089478 
	   C1063.530762,1328.607544 1075.666382,1363.046753 1085.912109,1398.059448 
	   C1096.295898,1433.544189 1107.584839,1468.763672 1118.387573,1504.126831 
	   C1119.380737,1507.378052 1119.724609,1510.827759 1120.715454,1515.982788 
	   C1123.029053,1512.921021 1124.811401,1511.432983 1125.472534,1509.551147 
	   C1131.296753,1492.968750 1136.718384,1476.243408 1142.655396,1459.702515 
	   C1150.572266,1437.645020 1158.703491,1415.661621 1166.992920,1393.740967 
	   C1183.192383,1350.902588 1199.813232,1308.222046 1215.767700,1265.293213 
	   C1224.510986,1241.767700 1232.180176,1217.844360 1240.502075,1194.159546 
	   C1242.144897,1189.484009 1244.226929,1184.887695 1246.703003,1180.599487 
	   C1249.610718,1175.563843 1253.376465,1172.130859 1260.266235,1172.469360 
	   C1266.794067,1172.789917 1268.812622,1177.049927 1270.712402,1181.518433 
	   C1275.844727,1193.590576 1280.605225,1205.821411 1285.463257,1218.009277 
	   C1292.735474,1236.253662 1299.908569,1254.537598 1307.227295,1272.763306 
	   C1315.423584,1293.175049 1323.681763,1313.562256 1332.009155,1333.920776 
	   C1344.531128,1364.534180 1357.214355,1395.081787 1369.683228,1425.716675 
	   C1380.707520,1452.802368 1391.512207,1479.977417 1402.495728,1507.079834 
	   C1403.171753,1508.747925 1404.566162,1510.125122 1406.478271,1512.853271 
	   C1407.951172,1509.572021 1409.271240,1507.678345 1409.659180,1505.609863 
	   C1415.564087,1474.133057 1425.650146,1443.825195 1435.005127,1413.292236 
	   C1445.142334,1380.205566 1454.542236,1346.893677 1464.454590,1313.737061 
	   C1470.906250,1292.155762 1477.781982,1270.701660 1484.303955,1249.140869 
	   C1487.585693,1238.291870 1491.100464,1227.454102 1493.353760,1216.376099 
	   C1494.653442,1209.985718 1494.902222,1202.818848 1493.364014,1196.556641 
	   C1491.453003,1188.777466 1483.903687,1186.073242 1476.692749,1184.098755 
	   C1473.242188,1183.153931 1469.559570,1182.801392 1466.339355,1181.374268 
	   C1464.643555,1180.622925 1462.997559,1178.247314 1462.712402,1176.392578 
	   C1462.541748,1175.283203 1464.819458,1173.099121 1466.393188,1172.564087 
	   C1469.770630,1171.415771 1473.393677,1170.491699 1476.935669,1170.418701 
	   C1488.259033,1170.185181 1499.602051,1170.088867 1510.916382,1170.510620 
	   C1519.115112,1170.816162 1527.021851,1173.632324 1535.493286,1172.749756 
	   C1549.559570,1171.284302 1563.713501,1170.659668 1578.817139,1168.820801 
	   C1587.270264,1169.588867 1590.354370,1175.090332 1592.051758,1181.817627 
	   C1584.873779,1182.754395 1577.457031,1182.860596 1570.578003,1184.837402 
	   C1561.931519,1187.322388 1555.724487,1193.581299 1552.388794,1202.040527 
	   C1545.391602,1219.785278 1538.425903,1237.549438 1531.893433,1255.468262 
	   C1523.894043,1277.410889 1516.213989,1299.472900 1508.640503,1321.567139 
	   C1494.106323,1363.967285 1479.708740,1406.414307 1465.326172,1448.866089 
	   C1457.588623,1471.704224 1449.770996,1494.519287 1442.402832,1517.477295 
	   C1433.901367,1543.966919 1426.205811,1570.718506 1417.476318,1597.130127 
	   C1412.448853,1612.341431 1406.740723,1627.413330 1400.077759,1641.970581 
	   C1395.068848,1652.914062 1386.636230,1653.052246 1380.061768,1642.718140 
	   C1375.684937,1635.838745 1372.448975,1628.147827 1369.350220,1620.555664 
	   C1356.692627,1589.542114 1344.423828,1558.369507 1331.736450,1527.368286 
	   C1322.879028,1505.725220 1313.481201,1484.304199 1304.493652,1462.713623 
	   C1293.530151,1436.375854 1282.723755,1409.972900 1271.886963,1383.582642 
	   C1262.165649,1359.909302 1252.500244,1336.212891 1242.787109,1312.536255 
	   C1242.332397,1311.427734 1241.651978,1310.411865 1240.190674,1307.721924 
	   C1238.519775,1313.925781 1237.583496,1318.661011 1235.970825,1323.153442 
	   C1231.074707,1336.791138 1225.940186,1350.343750 1220.856567,1363.913818 
	   C1209.048706,1395.433838 1197.518433,1427.062256 1185.315430,1458.428833 
	   C1170.776978,1495.798340 1155.792969,1532.996582 1140.728516,1570.158203 
	   C1132.257568,1591.054688 1123.293701,1611.752930 1114.407104,1632.478271 
	   C1112.728394,1636.393311 1110.850952,1640.399414 1108.229858,1643.700195 
	   C1100.936035,1652.885742 1090.921387,1651.567993 1086.520264,1640.759521 
	   C1083.201782,1632.610107 1080.703003,1624.123901 1077.914917,1615.762451 
	   C1069.488647,1590.492188 1061.017090,1565.236572 1052.717529,1539.924683 
	   C1043.674072,1512.344727 1034.919556,1484.669800 1025.820435,1457.108398 
	   C1018.736816,1435.651489 1011.195190,1414.345703 1004.080322,1392.898804 
	   C995.690796,1367.609497 987.530212,1342.244141 979.301880,1316.901489 
	   C978.097900,1313.193604 977.068115,1309.429321 975.587097,1304.443237 
	   C973.793335,1306.817505 972.027588,1308.103027 971.803833,1309.616577 
	   C969.950439,1322.153198 963.201111,1332.246826 955.909790,1342.039673 
	   C938.146057,1365.897827 914.333923,1381.705078 886.705811,1391.445068 
	   C869.708008,1397.437500 851.930176,1401.171387 832.267822,1401.517334 
	   C823.928101,1400.508789 817.082092,1399.905640 810.247559,1400.015259 
	   C806.157349,1400.080811 802.085022,1401.261597 798.004700,1401.941284 
	   C795.239868,1402.021729 792.475098,1402.102173 788.898926,1401.366455 
	   C786.209839,1397.827026 784.759216,1397.978394 783.763672,1401.192871 
	   C780.457092,1401.321533 777.150513,1401.450195 772.869995,1400.766357 
	   C767.405151,1397.010132 762.776123,1397.437744 758.084656,1399.387939 
	   C755.343018,1399.429443 752.601379,1399.470825 748.948242,1398.794678 
	   C745.479614,1395.757446 743.232910,1395.236450 741.753479,1399.161621 
   M832.380371,1371.789551 
	   C835.632446,1371.360229 838.894043,1370.992065 842.135193,1370.491943 
	   C867.739563,1366.540283 885.986938,1352.505737 895.269104,1328.594238 
	   C900.349243,1315.507324 903.550964,1301.369995 901.956543,1286.947388 
	   C900.869995,1277.119019 899.695251,1267.214355 897.394165,1257.623535 
	   C891.957825,1234.965576 883.202637,1213.660034 867.794373,1195.780762 
	   C853.122864,1178.756470 835.987488,1165.303345 813.827209,1158.827271 
	   C802.192871,1155.427246 790.925842,1150.647583 779.733093,1145.922119 
	   C775.500061,1144.135010 770.323059,1142.455688 769.302795,1135.101196 
	   C772.395386,1133.670898 775.673218,1131.272827 779.248596,1130.684937 
	   C786.697144,1129.460083 794.322998,1128.462280 801.840820,1128.666382 
	   C826.487976,1129.335327 842.983093,1117.604736 854.058533,1096.381958 
	   C859.552063,1085.855103 861.333557,1074.357910 862.173401,1063.055664 
	   C864.040283,1037.932739 859.416077,1013.856750 845.944031,992.036377 
	   C836.378540,976.543396 823.170410,965.286743 805.581726,960.412170 
	   C797.775452,958.248840 789.273193,958.242188 781.062866,957.964844 
	   C763.224976,957.362488 761.884399,958.868164 761.884277,976.924011 
	   C761.883301,1083.915894 761.804932,1190.907837 761.987732,1297.899536 
	   C762.010315,1311.128052 763.447876,1324.353149 764.194336,1337.581909 
	   C764.943909,1350.864258 771.625549,1359.111450 784.001343,1364.395874 
	   C799.076904,1370.833130 814.567932,1372.191528 832.380371,1371.789551 
   z"/>
   <path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	   d="
   M1614.180542,1546.237427 
	   C1633.995117,1542.641113 1653.901855,1539.482422 1673.600220,1535.334473 
	   C1696.732178,1530.463623 1718.647339,1521.767822 1739.990967,1511.750977 
	   C1766.671753,1499.229248 1790.071411,1481.866821 1808.413696,1458.952759 
	   C1824.994507,1438.239258 1834.049561,1414.026489 1837.010986,1387.126465 
	   C1840.502930,1355.407837 1833.288574,1325.770630 1821.695190,1297.196045 
	   C1809.469360,1267.062622 1792.428101,1239.412109 1772.365845,1213.612549 
	   C1748.192261,1182.525879 1721.172241,1154.221680 1692.139160,1127.704468 
	   C1656.227173,1094.904541 1617.804321,1065.386108 1577.285034,1038.535156 
	   C1562.721191,1028.884155 1548.699707,1018.350891 1533.728394,1009.394287 
	   C1511.026245,995.812561 1487.926025,982.854736 1464.605225,970.359009 
	   C1430.136475,951.890015 1395.064697,934.653748 1359.316650,918.698303 
	   C1320.956299,901.576782 1282.108521,885.753052 1242.503052,871.887146 
	   C1212.136108,861.255676 1181.374268,851.706848 1150.587280,842.335876 
	   C1129.442017,835.899536 1108.114746,829.886963 1086.578613,824.950439 
	   C1055.973877,817.935181 1025.191772,811.599548 994.316040,805.889954 
	   C970.679626,801.518921 946.842590,798.059998 922.978394,795.150391 
	   C905.329895,792.998535 887.433411,792.927124 869.755859,790.955566 
	   C843.649231,788.043823 817.482788,789.707520 791.355530,788.936707 
	   C782.133911,788.664673 772.813049,789.064026 763.646851,790.112488 
	   C736.767334,793.186951 709.740417,795.610596 683.143738,800.370789 
	   C642.171997,807.703796 602.837952,820.631653 567.549988,843.336487 
	   C539.121338,861.628052 516.483215,885.476501 503.901093,917.522034 
	   C498.826233,930.447266 496.279846,943.976990 495.039886,957.919617 
	   C492.224945,989.571777 499.694153,1019.375671 512.347229,1047.612549 
	   C520.056274,1064.816406 531.840942,1080.193970 541.684814,1097.648926 
	   C541.193542,1101.106934 540.812500,1103.307861 540.257751,1105.709595 
	   C540.084106,1105.910522 539.733948,1106.309570 539.131958,1105.994629 
	   C527.287781,1091.129272 516.008911,1076.606812 504.813293,1062.020508 
	   C492.303345,1045.721802 483.021759,1027.515991 473.141479,1009.589844 
	   C463.200775,991.554016 456.350342,972.660339 451.607452,952.777771 
	   C451.145264,950.840271 448.317993,949.467041 446.588501,947.831848 
	   C446.564911,947.025024 446.541321,946.218262 447.323853,944.655884 
	   C448.264740,942.378235 448.722656,940.803528 448.484894,939.341980 
	   C445.648865,921.909058 445.458252,904.579956 447.781097,886.959778 
	   C450.339508,867.552307 456.527130,849.809143 467.043365,833.450684 
	   C469.076874,830.287354 470.908081,827.152466 468.913422,823.279114 
	   C468.913422,823.279114 469.096954,823.125671 469.870422,823.303955 
	   C475.505798,822.081238 476.065674,818.231140 476.021088,814.036865 
	   C496.090881,789.935547 521.250305,772.471252 549.426086,759.479492 
	   C576.323364,747.077209 604.311340,737.560059 633.552185,732.116943 
	   C650.669556,728.930664 667.694946,725.139099 684.907532,722.613586 
	   C696.981873,720.841858 709.290405,720.427429 721.515442,719.971924 
	   C745.354126,719.083862 769.205811,718.177307 793.055542,718.075134 
	   C810.256592,718.001465 827.468750,719.096436 844.665283,719.898804 
	   C856.540466,720.452942 868.438843,721.007019 880.252747,722.259766 
	   C897.435730,724.081970 914.556030,726.502686 931.695496,728.726318 
	   C944.737000,730.418274 957.827820,731.838196 970.788391,734.022217 
	   C993.761292,737.893372 1016.740601,741.823547 1039.551880,746.529968 
	   C1062.016968,751.164978 1084.411133,756.284302 1106.593262,762.115295 
	   C1134.535522,769.460510 1162.373657,777.280518 1189.989746,785.764343 
	   C1213.175903,792.887390 1236.020020,801.139160 1258.940063,809.108093 
	   C1278.837158,816.025879 1298.873657,822.637268 1318.409180,830.478333 
	   C1347.756226,842.257507 1376.903442,854.580505 1405.789185,867.449829 
	   C1429.487427,878.008118 1452.898682,889.269531 1476.047974,900.988098 
	   C1512.278320,919.328430 1547.643311,939.223083 1582.604248,959.947571 
	   C1612.837158,977.869385 1641.787598,997.583374 1670.506470,1017.672485 
	   C1712.720581,1047.201782 1752.517822,1079.724854 1789.605347,1115.591064 
	   C1810.442261,1135.741943 1830.074463,1156.912964 1847.997559,1179.633057 
	   C1865.623291,1201.976440 1882.547852,1224.932129 1895.460571,1250.378052 
	   C1906.265259,1271.669189 1916.663208,1293.354980 1921.664185,1316.891235 
	   C1924.930786,1332.264282 1926.479126,1347.901489 1926.419434,1363.820312 
	   C1926.369507,1377.126099 1925.109985,1390.214478 1921.531860,1402.698486 
	   C1916.815674,1419.152588 1908.772827,1434.333252 1898.524414,1448.284424 
	   C1881.149414,1471.937256 1858.838379,1489.491699 1833.137207,1503.254883 
	   C1813.085205,1513.992920 1792.030518,1522.346191 1770.432007,1529.009888 
	   C1754.544678,1533.911499 1737.971924,1536.647217 1721.638184,1540.027100 
	   C1710.384399,1542.355835 1699.017090,1544.135620 1686.367432,1545.652588 
	   C1681.402832,1543.850586 1677.951050,1543.333130 1675.546631,1547.340332 
	   C1672.891235,1547.331299 1670.235718,1547.322144 1666.365723,1547.021362 
	   C1663.329712,1547.677979 1661.508423,1548.626221 1659.687134,1549.574463 
	   C1655.046021,1549.756714 1650.405029,1549.939087 1644.265625,1549.640869 
	   C1636.894043,1548.769287 1630.644775,1545.575928 1625.613525,1551.461670 
	   C1620.415771,1551.531982 1615.217896,1551.602173 1608.888672,1550.957520 
	   C1607.553101,1549.286865 1607.348877,1548.331177 1607.144775,1547.375488 
	   C1607.542114,1547.217651 1607.939575,1547.059937 1609.510132,1547.289062 
	   C1611.848999,1547.196655 1613.014771,1546.717041 1614.180542,1546.237427 
   z"/>
   <path fill="#FCFCFC" opacity="1.000000" stroke="none" 
	   d="
   M1592.346802,1182.404053 
	   C1590.354370,1175.090332 1587.270264,1169.588867 1579.093018,1167.930176 
	   C1577.756348,1167.503540 1577.130493,1167.107178 1576.193970,1165.500244 
	   C1573.353516,1159.475708 1570.147339,1155.630981 1564.001465,1155.999634 
	   C1562.290283,1154.808960 1560.579102,1153.618408 1558.427490,1151.248169 
	   C1556.061768,1145.092651 1552.976685,1141.925903 1547.111450,1143.095215 
	   C1547.111450,1143.095215 1547.107666,1142.904175 1547.260742,1142.240967 
	   C1545.524170,1136.384521 1541.626221,1134.102417 1536.290527,1133.904541 
	   C1535.621338,1133.663818 1534.952271,1133.423096 1534.039795,1131.946289 
	   C1531.891479,1129.792725 1529.986328,1128.875122 1528.081299,1127.957520 
	   C1528.081299,1127.957520 1528.028687,1128.014771 1527.941162,1127.265259 
	   C1526.766235,1122.285889 1523.524170,1121.859009 1519.941650,1122.033081 
	   C1519.941650,1122.033081 1519.995972,1121.994141 1519.870483,1121.312256 
	   C1518.199951,1120.120972 1516.654907,1119.611694 1515.109863,1119.102417 
	   C1515.109863,1119.102417 1515.105103,1118.917969 1515.252197,1118.217285 
	   C1514.377319,1112.139160 1510.095581,1112.257080 1506.052612,1111.972656 
	   C1506.052612,1111.972656 1505.995605,1112.000000 1505.902466,1111.285400 
	   C1503.866333,1109.717407 1501.923462,1108.864014 1499.980469,1108.010742 
	   C1499.980469,1108.010742 1500.000000,1108.000000 1499.907104,1107.330200 
	   C1497.938232,1105.744507 1496.062378,1104.828735 1494.186401,1103.912842 
	   C1494.186401,1103.912842 1494.048340,1103.978638 1493.965454,1103.209229 
	   C1491.200073,1096.368896 1486.220337,1094.114624 1479.928345,1094.040405 
	   C1479.928345,1094.040405 1480.012085,1094.016235 1479.908203,1093.357910 
	   C1474.932739,1089.010742 1470.289185,1084.949951 1465.105469,1081.769897 
	   C1461.885376,1079.794556 1457.846680,1079.153442 1454.175415,1077.913574 
	   C1454.175415,1077.913574 1454.074219,1078.005981 1453.961182,1077.218506 
	   C1452.296265,1071.238770 1447.965942,1072.095093 1444.095215,1071.950928 
	   C1444.095215,1071.950928 1444.025635,1072.015991 1443.942871,1071.261230 
	   C1442.592651,1069.096313 1441.583008,1067.227783 1440.017700,1066.347534 
	   C1425.685791,1058.288330 1411.468384,1049.980103 1396.781128,1042.614502 
	   C1387.416260,1037.918091 1378.845337,1031.115723 1367.866699,1030.049072 
	   C1367.526123,1030.020630 1367.185669,1029.992310 1366.279297,1029.018799 
	   C1365.322144,1027.551147 1365.045044,1026.789307 1364.523315,1026.540894 
	   C1345.756226,1017.604980 1326.995972,1008.652893 1308.128784,999.932129 
	   C1306.453613,999.157898 1304.062866,999.932129 1302.004395,999.987183 
	   C1300.852539,999.707764 1299.700806,999.428284 1298.156982,997.896729 
	   C1287.993164,989.824585 1276.929321,986.380371 1265.364624,984.244507 
	   C1264.577881,984.214294 1263.791138,984.184082 1262.357666,983.153198 
	   C1249.603516,974.339966 1235.686157,971.013367 1222.306641,966.372375 
	   C1221.788208,966.192566 1220.791748,967.391174 1220.017944,967.947510 
	   C1218.399658,967.377197 1216.781494,966.806946 1214.432129,965.237000 
	   C1212.700928,963.472473 1211.813599,962.363953 1210.683960,961.993225 
	   C1186.512573,954.059265 1162.363647,946.047485 1138.055908,938.551392 
	   C1135.132812,937.649963 1131.472534,934.633179 1127.988892,938.033508 
	   C1127.067383,938.030945 1126.145752,938.028381 1124.472900,937.063354 
	   C1120.770874,934.096069 1118.094482,930.849915 1114.014526,933.956116 
	   C1113.090210,934.022705 1112.165771,934.089294 1110.447144,933.140381 
	   C1102.090210,929.615662 1094.620972,926.749023 1086.927246,924.742065 
	   C1082.835327,923.674500 1078.354858,924.095886 1074.049805,923.845337 
	   C1073.117676,923.900574 1072.185547,923.955811 1070.461670,923.025696 
	   C1066.070190,919.682373 1062.387573,917.803589 1057.995117,920.014282 
	   C1056.462891,920.020691 1054.930786,920.027039 1052.536865,919.052185 
	   C1048.087524,915.655273 1044.398804,913.812134 1039.996826,916.009521 
	   C1038.469482,916.057495 1036.942261,916.105408 1034.586182,915.243408 
	   C1031.732666,911.255676 1030.649048,912.893433 1029.687988,915.145569 
	   C1028.264282,914.696167 1026.840576,914.246704 1024.578369,912.905518 
	   C1021.930664,909.277161 1020.023315,909.600037 1018.054749,911.831299 
	   C1017.141235,911.937500 1016.227783,912.043640 1014.592773,911.369263 
	   C1013.034729,908.298828 1011.817383,907.795776 1010.070251,909.778564 
	   C1007.894226,909.785645 1005.718262,909.792725 1002.664734,908.936584 
	   C1000.352905,905.728577 999.083313,906.306458 997.893188,908.292908 
	   C991.812927,907.412598 985.732666,906.532288 978.680664,904.775635 
	   C975.716980,901.246033 973.817383,901.687683 971.975586,904.070129 
	   C965.191345,903.332703 958.407104,902.595337 950.673584,900.925293 
	   C947.735596,897.217957 945.822632,897.690186 943.955505,900.127136 
	   C941.187012,899.929688 938.418579,899.732239 934.691528,898.747986 
	   C931.864380,895.289185 929.960632,895.637146 928.034546,897.894653 
	   C924.571106,897.897156 921.107727,897.899658 916.664429,896.966064 
	   C913.173035,892.943909 910.613464,893.366821 908.024658,895.925171 
	   C903.934265,895.795776 899.843811,895.666382 894.708374,894.741821 
	   C891.089600,890.971497 888.537842,891.343140 886.001099,893.996826 
	   C877.762756,893.466492 869.526001,892.910034 861.285278,892.419861 
	   C858.297607,892.242126 855.300537,892.142761 852.307800,892.144714 
	   C835.315552,892.155823 818.311157,891.824341 801.334717,892.362183 
	   C786.440979,892.834167 771.561890,894.102295 756.707397,895.380188 
	   C743.572937,896.510193 730.432800,897.792908 717.381348,899.622742 
	   C693.864075,902.919800 670.574097,907.485901 648.125366,915.432007 
	   C634.141418,920.381897 620.117004,925.369385 606.577576,931.389954 
	   C582.194031,942.232666 560.376404,956.899536 542.949646,977.498352 
	   C541.741516,978.926453 540.123230,980.007446 537.792847,982.035645 
	   C537.379089,978.803528 536.553589,976.526306 536.902039,974.445740 
	   C541.461792,947.221130 552.640625,923.294006 571.722473,903.008362 
	   C596.934998,876.205078 628.900269,861.294434 663.088806,850.359314 
	   C696.113281,839.796448 730.230469,834.307251 764.705078,832.094299 
	   C790.535156,830.436279 816.581055,829.479370 842.397156,830.808105 
	   C899.332214,833.738647 955.852478,840.752625 1011.958130,851.278381 
	   C1087.169189,865.388367 1160.772461,885.387634 1233.013184,910.394836 
	   C1283.909058,928.013123 1333.776855,948.249390 1382.543457,971.219360 
	   C1441.300903,998.895081 1498.066528,1030.048828 1551.912964,1066.402710 
	   C1585.484131,1089.067993 1617.637207,1113.526855 1647.930908,1140.536621 
	   C1681.029907,1170.047363 1711.236084,1201.992554 1736.148926,1238.729858 
	   C1756.859741,1269.270874 1773.172729,1301.853394 1778.811401,1338.780640 
	   C1785.169800,1380.422241 1775.788452,1418.235107 1748.363281,1450.550049 
	   C1728.487915,1473.969360 1703.492065,1490.978027 1675.536743,1503.531250 
	   C1628.442261,1524.678833 1578.753784,1535.453125 1527.563110,1540.134766 
	   C1517.023193,1541.098755 1506.510620,1542.362305 1495.078979,1542.882568 
	   C1494.121460,1541.525635 1494.070312,1540.773926 1494.019165,1540.022217 
	   C1507.509521,1537.485107 1520.999756,1534.947998 1535.771484,1532.791260 
	   C1550.067139,1529.523193 1563.106323,1525.958862 1576.062622,1522.115723 
	   C1577.608521,1521.657104 1578.703613,1519.678345 1580.007568,1518.404053 
	   C1581.561890,1517.585449 1583.116089,1516.766846 1585.740723,1516.464355 
	   C1592.170410,1516.499878 1597.221680,1515.431274 1600.020630,1510.062256 
	   C1600.958374,1509.379150 1601.896240,1508.696167 1603.855957,1508.548096 
	   C1618.595337,1505.183838 1630.114624,1497.178833 1641.582031,1489.159546 
	   C1642.931030,1488.216309 1643.220459,1485.757935 1644.000977,1484.001953 
	   C1644.000977,1484.001953 1644.000000,1484.000000 1644.741333,1484.005859 
	   C1651.275024,1481.864014 1657.973877,1480.690552 1658.009521,1472.355835 
	   C1660.322266,1469.751221 1662.635010,1467.146606 1666.286499,1464.280884 
	   C1676.649536,1460.440796 1679.803955,1452.605347 1682.058472,1444.117188 
	   C1682.058472,1444.117188 1682.004761,1444.005127 1682.763428,1443.940430 
	   C1685.281006,1442.040161 1687.502686,1440.469849 1688.728638,1438.329102 
	   C1703.414307,1412.685547 1706.282837,1384.578369 1703.372803,1356.069214 
	   C1700.939575,1332.230469 1691.588135,1310.085693 1681.006104,1288.671631 
	   C1679.668457,1285.964600 1676.595581,1284.114868 1674.326660,1281.868042 
	   C1674.326660,1281.868042 1674.093384,1281.849731 1674.092529,1280.986084 
	   C1671.224121,1269.837646 1665.111938,1261.378662 1658.676025,1253.146606 
	   C1658.194580,1252.531006 1656.261963,1253.049805 1655.004028,1253.041260 
	   C1655.004028,1253.041382 1655.391846,1252.773926 1655.427246,1252.089111 
	   C1655.222534,1247.464722 1654.190186,1244.206909 1649.312866,1244.257812 
	   C1648.583984,1244.243164 1648.112793,1243.887207 1647.920410,1241.932129 
	   C1647.824585,1237.408569 1646.489868,1235.787842 1643.029663,1237.037598 
	   C1643.029663,1237.037598 1643.355103,1236.715576 1643.386719,1236.122437 
	   C1642.221191,1234.386230 1641.024292,1233.243042 1639.827271,1232.099976 
	   C1639.827271,1232.099976 1639.990234,1232.045288 1639.968750,1231.328613 
	   C1639.657837,1226.302246 1638.201538,1223.440308 1633.104004,1225.095825 
	   C1633.104004,1225.095825 1633.091187,1224.910645 1633.267456,1224.190430 
	   C1629.935425,1216.739868 1627.815796,1208.644165 1618.176025,1207.942383 
	   C1616.957397,1206.870728 1615.738892,1205.799194 1614.286377,1203.434448 
	   C1611.794189,1195.032349 1607.034302,1190.401245 1600.034668,1187.988281 
	   C1597.570435,1186.322388 1595.106079,1184.656494 1592.346802,1182.404053 
   z"/>
   <path fill="#BCBCC5" opacity="1.000000" stroke="none" 
	   d="
   M446.387878,948.599182 
	   C448.317993,949.467041 451.145264,950.840271 451.607452,952.777771 
	   C456.350342,972.660339 463.200775,991.554016 473.141479,1009.589844 
	   C483.021759,1027.515991 492.303345,1045.721802 504.813293,1062.020508 
	   C516.008911,1076.606812 527.287781,1091.129272 539.416138,1106.472412 
	   C541.299011,1107.667969 542.295654,1108.070923 543.292297,1108.473877 
	   C542.338684,1107.485474 541.385071,1106.497070 540.431396,1105.508667 
	   C540.812500,1103.307861 541.193542,1101.106934 541.904297,1098.300293 
	   C556.791260,1114.424194 575.205994,1140.466919 576.123047,1145.856079 
	   C570.502197,1147.970337 567.375427,1143.854126 564.400513,1140.659180 
	   C555.372375,1130.963379 546.417358,1121.175903 537.901978,1111.031128 
	   C517.434448,1086.647339 498.081360,1061.428101 481.598572,1034.106079 
	   C467.641663,1010.970764 456.335114,986.743164 450.073792,960.319153 
	   C449.185822,956.571716 447.502472,953.012695 446.387878,948.599182 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1674.354004,1282.598877 
	   C1676.595581,1284.114868 1679.668457,1285.964600 1681.006104,1288.671631 
	   C1691.588135,1310.085693 1700.939575,1332.230469 1703.372803,1356.069214 
	   C1706.282837,1384.578369 1703.414307,1412.685547 1688.728638,1438.329102 
	   C1687.502686,1440.469849 1685.281006,1442.040161 1682.819824,1443.966064 
	   C1684.436768,1439.005371 1686.609619,1433.881104 1689.099854,1428.915894 
	   C1700.041138,1407.100342 1702.466187,1383.616699 1700.061157,1360.019043 
	   C1697.284912,1332.778442 1687.302856,1307.438354 1674.354004,1282.598877 
   z"/>
   <path fill="#BABABE" opacity="1.000000" stroke="none" 
	   d="
   M684.344727,1000.731995 
	   C685.743286,1001.483398 687.748352,1003.099609 687.847473,1004.825378 
	   C688.220642,1011.322998 687.973267,1017.856201 687.288940,1025.908325 
	   C686.416138,1029.411865 686.035583,1031.383057 686.034790,1033.354492 
	   C686.004761,1110.643799 685.991821,1187.933105 686.078064,1265.222290 
	   C686.081238,1268.067139 687.097595,1270.910889 687.641724,1273.755127 
	   C687.812134,1280.540283 689.997803,1287.473267 685.347778,1294.066650 
	   C684.353882,1292.193359 684.009521,1290.217407 684.009644,1288.241333 
	   C684.013000,1192.709961 684.046204,1097.178589 684.344727,1000.731995 
   z"/>
   <path fill="#BCBCC5" opacity="1.000000" stroke="none" 
	   d="
   M468.383728,823.659424 
	   C470.908081,827.152466 469.076874,830.287354 467.043365,833.450684 
	   C456.527130,849.809143 450.339508,867.552307 447.781097,886.959778 
	   C445.458252,904.579956 445.648865,921.909058 448.484894,939.341980 
	   C448.722656,940.803528 448.264740,942.378235 447.485626,944.068481 
	   C445.267822,937.997986 443.694427,931.759338 442.731750,924.072876 
	   C443.484558,915.202454 443.772034,907.778381 443.693146,900.358093 
	   C443.663910,897.607239 442.774536,894.865540 442.277405,892.119629 
	   C442.660309,890.464539 443.043243,888.809387 444.188171,886.234497 
	   C444.732117,883.584961 444.514038,881.855164 444.295959,880.125366 
	   C448.376526,859.845764 456.088226,841.091309 468.383728,823.659424 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1368.144531,1030.683472 
	   C1378.845337,1031.115723 1387.416260,1037.918091 1396.781128,1042.614502 
	   C1411.468384,1049.980103 1425.685791,1058.288330 1440.017700,1066.347534 
	   C1441.583008,1067.227783 1442.592651,1069.096313 1443.948730,1071.214111 
	   C1435.348389,1067.335938 1426.627319,1062.809448 1417.976807,1058.151733 
	   C1401.437378,1049.246460 1384.938110,1040.266724 1368.144531,1030.683472 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1128.444824,938.607422 
	   C1131.472534,934.633179 1135.132812,937.649963 1138.055908,938.551392 
	   C1162.363647,946.047485 1186.512573,954.059265 1210.683960,961.993225 
	   C1211.813599,962.363953 1212.700928,963.472473 1213.854126,964.922729 
	   C1203.023804,962.140930 1192.055176,958.626221 1181.053711,955.217590 
	   C1163.681030,949.834778 1146.286255,944.523132 1128.444824,938.607422 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1302.390869,1000.571716 
	   C1304.062866,999.932129 1306.453613,999.157898 1308.128784,999.932129 
	   C1326.995972,1008.652893 1345.756226,1017.604980 1364.523315,1026.540894 
	   C1365.045044,1026.789307 1365.322144,1027.551147 1365.864258,1028.718262 
	   C1356.267334,1024.948242 1346.540039,1020.487122 1336.765991,1016.131042 
	   C1325.457764,1011.091248 1314.108643,1006.143799 1302.390869,1000.571716 
   z"/>
   <path fill="#B6B6BE" opacity="1.000000" stroke="none" 
	   d="
   M651.363953,1386.108398 
	   C652.310730,1390.054688 644.836609,1392.065430 648.802551,1396.093994 
	   C650.766052,1398.088501 655.403381,1397.541382 658.853638,1397.955322 
	   C662.099792,1398.344727 665.375793,1398.485596 669.290771,1399.207520 
	   C664.524170,1400.462280 659.124634,1401.578247 653.678894,1401.890137 
	   C650.897156,1402.049683 647.850037,1401.185913 645.245789,1400.046509 
	   C640.551270,1397.992920 639.925232,1394.770386 643.554626,1390.919189 
	   C645.480103,1388.876099 648.264221,1387.642334 651.363953,1386.108398 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1643.302490,1484.075439 
	   C1643.220459,1485.757935 1642.931030,1488.216309 1641.582031,1489.159546 
	   C1630.114624,1497.178833 1618.595337,1505.183838 1604.461304,1508.600708 
	   C1616.897949,1500.128418 1629.750854,1492.138672 1643.302490,1484.075439 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1579.245850,1518.264771 
	   C1578.703613,1519.678345 1577.608521,1521.657104 1576.062622,1522.115723 
	   C1563.106323,1525.958862 1550.067139,1529.523193 1536.571777,1532.708618 
	   C1537.373169,1531.362427 1538.537109,1530.091309 1539.957153,1529.656006 
	   C1552.773926,1525.729126 1565.635498,1521.948730 1579.245850,1518.264771 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1220.426880,968.560669 
	   C1220.791748,967.391174 1221.788208,966.192566 1222.306641,966.372375 
	   C1235.686157,971.013367 1249.603516,974.339966 1261.860840,982.821899 
	   C1251.370117,979.675232 1240.739624,975.830322 1230.082031,972.061340 
	   C1227.040039,970.985596 1223.920044,970.130249 1220.426880,968.560669 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1655.266479,1253.666382 
	   C1656.261963,1253.049805 1658.194580,1252.531006 1658.676025,1253.146606 
	   C1665.111938,1261.378662 1671.224121,1269.837646 1674.124756,1280.873535 
	   C1667.948242,1272.513794 1661.738525,1263.402588 1655.266479,1253.666382 
   z"/>
   <path fill="#B6B6BE" opacity="1.000000" stroke="none" 
	   d="
   M798.757446,1402.231201 
	   C802.085022,1401.261597 806.157349,1400.080811 810.247559,1400.015259 
	   C817.082092,1399.905640 823.928101,1400.508789 831.380188,1401.372803 
	   C821.164124,1402.129395 810.337097,1402.325195 798.757446,1402.231201 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1074.649170,924.288147 
	   C1078.354858,924.095886 1082.835327,923.674500 1086.927246,924.742065 
	   C1094.620972,926.749023 1102.090210,929.615662 1109.831787,932.814758 
	   C1098.423340,930.580078 1086.835815,927.655579 1074.649170,924.288147 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1265.675049,984.900879 
	   C1276.929321,986.380371 1287.993164,989.824585 1297.871826,997.353638 
	   C1287.314087,993.894165 1276.649902,989.725769 1265.675049,984.900879 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1454.476074,1078.512817 
	   C1457.846680,1079.153442 1461.885376,1079.794556 1465.105469,1081.769897 
	   C1470.289185,1084.949951 1474.932739,1089.010742 1479.879517,1093.391113 
	   C1471.562012,1089.092651 1463.169312,1084.102295 1454.476074,1078.512817 
   z"/>
   <path fill="#858585" opacity="1.000000" stroke="none" 
	   d="
   M1493.138916,1539.887451 
	   C1494.070312,1540.773926 1494.121460,1541.525635 1494.270508,1542.866211 
	   C1486.593018,1543.913452 1478.818359,1544.390015 1471.039185,1544.773071 
	   C1470.676880,1544.790894 1470.273560,1543.974731 1469.239014,1542.822876 
	   C1477.258667,1541.753296 1484.758667,1540.752930 1493.138916,1539.887451 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1618.300293,1208.601562 
	   C1627.815796,1208.644165 1629.935425,1216.739868 1633.174805,1224.185181 
	   C1628.078735,1219.687012 1623.251709,1214.473999 1618.300293,1208.601562 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1681.412231,1444.306763 
	   C1679.803955,1452.605347 1676.649536,1460.440796 1666.895142,1464.037109 
	   C1671.031982,1457.535156 1675.899048,1451.015747 1681.412231,1444.306763 
   z"/>
   <path fill="#797985" opacity="1.000000" stroke="none" 
	   d="
   M1598.388428,1551.442017 
	   C1590.213013,1551.442017 1582.037476,1551.442017 1572.545776,1551.442017 
	   C1576.948120,1547.426758 1581.163330,1550.333008 1584.834473,1549.817017 
	   C1588.684204,1549.276001 1592.517456,1548.618530 1597.120361,1548.603516 
	   C1598.051270,1549.944092 1598.219849,1550.692993 1598.388428,1551.442017 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1600.191895,1188.648926 
	   C1607.034302,1190.401245 1611.794189,1195.032349 1614.092041,1202.843750 
	   C1609.537598,1198.800537 1604.943359,1194.055054 1600.191895,1188.648926 
   z"/>
   <path fill="#797985" opacity="1.000000" stroke="none" 
	   d="
   M1626.462646,1551.777100 
	   C1630.644775,1545.575928 1636.894043,1548.769287 1643.387939,1549.626953 
	   C1638.442871,1550.759644 1632.877197,1551.426025 1626.462646,1551.777100 
   z"/>
   <path fill="#BABABE" opacity="1.000000" stroke="none" 
	   d="
   M682.286865,1334.688843 
	   C683.121216,1334.059326 684.218628,1334.234375 684.241577,1334.501587 
	   C684.668518,1339.467651 685.148132,1344.445190 685.134338,1349.418945 
	   C685.131592,1350.364136 683.068359,1351.303345 681.299072,1352.224487 
	   C681.111511,1346.647949 681.580688,1341.092163 682.286865,1334.688843 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1657.352051,1472.413086 
	   C1657.973877,1480.690552 1651.275024,1481.864014 1644.742432,1484.006348 
	   C1648.233032,1480.157349 1652.463867,1476.313843 1657.352051,1472.413086 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1480.083862,1094.707520 
	   C1486.220337,1094.114624 1491.200073,1096.368896 1493.980225,1103.134277 
	   C1489.465088,1101.010620 1484.852173,1098.192627 1480.083862,1094.707520 
   z"/>
   <path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	   d="
   M442.033203,893.017334 
	   C442.774536,894.865540 443.663910,897.607239 443.693146,900.358093 
	   C443.772034,907.778381 443.484558,915.202454 442.809875,923.242432 
	   C442.114502,913.878174 441.951721,903.896606 442.033203,893.017334 
   z"/>
   <path fill="#B6B6BE" opacity="1.000000" stroke="none" 
	   d="
   M758.732788,1399.838867 
	   C762.776123,1397.437744 767.405151,1397.010132 772.046692,1400.673096 
	   C767.925171,1401.024902 763.653076,1400.657349 758.732788,1399.838867 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1564.182373,1156.644287 
	   C1570.147339,1155.630981 1573.353516,1159.475708 1575.994751,1164.966797 
	   C1572.191772,1162.859131 1568.277588,1160.074097 1564.182373,1156.644287 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1599.297363,1510.098267 
	   C1597.221680,1515.431274 1592.170410,1516.499878 1586.401978,1516.481079 
	   C1590.186401,1514.032471 1594.380249,1512.083374 1599.297363,1510.098267 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1536.520996,1134.513916 
	   C1541.626221,1134.102417 1545.524170,1136.384521 1547.177002,1142.287109 
	   C1543.544067,1140.372070 1540.147827,1137.747681 1536.520996,1134.513916 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1547.538330,1143.554199 
	   C1552.976685,1141.925903 1556.061768,1145.092651 1558.001465,1150.778320 
	   C1554.665771,1148.996460 1551.315552,1146.504883 1547.538330,1143.554199 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1040.486328,916.531006 
	   C1044.398804,913.812134 1048.087524,915.655273 1051.845215,918.717468 
	   C1048.335449,918.593506 1044.655762,917.822937 1040.486328,916.531006 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1058.460693,920.552368 
	   C1062.387573,917.803589 1066.070190,919.682373 1069.843018,922.684448 
	   C1066.319702,922.582458 1062.622925,921.836487 1058.460693,920.552368 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1444.349365,1072.575806 
	   C1447.965942,1072.095093 1452.296265,1071.238770 1453.954224,1077.150391 
	   C1450.907959,1076.313354 1447.755737,1074.756958 1444.349365,1072.575806 
   z"/>
   <path fill="#797985" opacity="1.000000" stroke="none" 
	   d="
   M1676.272217,1547.695312 
	   C1677.951050,1543.333130 1681.402832,1543.850586 1685.519531,1545.629150 
	   C1683.002563,1546.759521 1680.000244,1547.404907 1676.272217,1547.695312 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1506.201416,1112.609009 
	   C1510.095581,1112.257080 1514.377319,1112.139160 1515.162476,1118.238525 
	   C1512.067017,1117.055298 1509.208618,1115.150391 1506.201416,1112.609009 
   z"/>
   <path fill="#BCBCC5" opacity="1.000000" stroke="none" 
	   d="
   M475.411194,814.238892 
	   C476.065674,818.231140 475.505798,822.081238 469.950195,823.214844 
	   C471.104736,820.111938 472.953003,817.276489 475.411194,814.238892 
   z"/>
   <path fill="#BABABE" opacity="1.000000" stroke="none" 
	   d="
   M683.432068,1318.269287 
	   C685.228821,1318.856323 686.343750,1319.705078 687.458618,1320.553955 
	   C686.263245,1321.737915 685.067810,1322.921875 683.197083,1324.135254 
	   C682.597900,1322.286743 682.674072,1320.408813 683.432068,1318.269287 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1114.414185,934.515625 
	   C1118.094482,930.849915 1120.770874,934.096069 1123.868652,936.722900 
	   C1120.948486,936.588257 1117.881104,935.831665 1114.414185,934.515625 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1633.519531,1225.554932 
	   C1638.201538,1223.440308 1639.657837,1226.302246 1639.915771,1231.396240 
	   C1637.901245,1230.125122 1635.918213,1228.069702 1633.519531,1225.554932 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1649.441162,1244.913086 
	   C1654.190186,1244.206909 1655.222534,1247.464722 1655.201660,1252.156982 
	   C1653.150269,1250.462524 1651.359985,1248.015503 1649.441162,1244.913086 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M886.477051,894.529419 
	   C888.537842,891.343140 891.089600,890.971497 893.841797,894.565308 
	   C891.664551,895.143433 889.308777,895.102661 886.477051,894.529419 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M908.661987,896.173401 
	   C910.613464,893.366821 913.173035,892.943909 915.851196,896.653809 
	   C913.778320,896.992310 911.538818,896.706970 908.661987,896.173401 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1520.143555,1122.658081 
	   C1523.524170,1121.859009 1526.766235,1122.285889 1527.941895,1127.226929 
	   C1525.468506,1126.386475 1522.906982,1124.834717 1520.143555,1122.658081 
   z"/>
   <path fill="#B6B6BE" opacity="1.000000" stroke="none" 
	   d="
   M742.539856,1399.418335 
	   C743.232910,1395.236450 745.479614,1395.757446 748.134644,1398.672852 
	   C746.597168,1399.403931 744.961670,1399.539551 742.539856,1399.418335 
   z"/>
   <path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	   d="
   M1599.037598,1551.446289 
	   C1598.219849,1550.692993 1598.051270,1549.944092 1597.965820,1548.623779 
	   C1599.505249,1548.039062 1600.961426,1548.025513 1603.206055,1548.630371 
	   C1603.857300,1549.969360 1603.719849,1550.689941 1603.582520,1551.410522 
	   C1602.283936,1551.423828 1600.985474,1551.437256 1599.037598,1551.446289 
   z"/>
   <path fill="#797985" opacity="1.000000" stroke="none" 
	   d="
   M1660.371338,1549.839355 
	   C1661.508423,1548.626221 1663.329712,1547.677979 1665.573853,1547.127441 
	   C1664.349609,1548.384766 1662.702637,1549.244507 1660.371338,1549.839355 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M928.594116,898.205872 
	   C929.960632,895.637146 931.864380,895.289185 933.877808,898.537842 
	   C932.399658,898.915344 930.776733,898.716187 928.594116,898.205872 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M944.392883,900.618652 
	   C945.822632,897.690186 947.735596,897.217957 949.872559,900.585083 
	   C948.290649,901.155029 946.560486,901.132629 944.392883,900.618652 
   z"/>
   <path fill="#B6B6BE" opacity="1.000000" stroke="none" 
	   d="
   M690.560181,1399.441162 
	   C691.175415,1395.220825 693.476318,1395.832520 696.166016,1398.578369 
	   C694.623291,1399.342407 692.989685,1399.510254 690.560181,1399.441162 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M972.387329,904.593140 
	   C973.817383,901.687683 975.716980,901.246033 977.866455,904.478760 
	   C976.282410,905.077637 974.540771,905.096924 972.387329,904.593140 
   z"/>
   <path fill="#BEBDC6" opacity="1.000000" stroke="none" 
	   d="
   M661.541260,1384.369385 
	   C661.708496,1388.365479 659.545349,1388.365234 656.404297,1386.576904 
	   C657.649597,1385.431396 659.281982,1384.774902 661.541260,1384.369385 
   z"/>
   <path fill="#BABABE" opacity="1.000000" stroke="none" 
	   d="
   M679.114319,1364.236206 
	   C682.620850,1365.937744 682.591797,1367.995483 679.291382,1370.161133 
	   C678.563599,1368.302856 678.502075,1366.408325 679.114319,1364.236206 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1018.586853,912.197388 
	   C1020.023315,909.600037 1021.930664,909.277161 1023.880371,912.590149 
	   C1022.386963,912.965576 1020.752991,912.764526 1018.586853,912.197388 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1643.273438,1237.594971 
	   C1646.489868,1235.787842 1647.824585,1237.408569 1647.924194,1241.461182 
	   C1646.443848,1240.882690 1644.980591,1239.517456 1643.273438,1237.594971 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1494.380127,1104.505615 
	   C1496.062378,1104.828735 1497.938232,1105.744507 1499.901855,1107.340088 
	   C1498.184326,1107.046143 1496.379028,1106.072388 1494.380127,1104.505615 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1528.370605,1128.513062 
	   C1529.986328,1128.875122 1531.891479,1129.792725 1533.881470,1131.402588 
	   C1532.197632,1131.086060 1530.428711,1130.077393 1528.370605,1128.513062 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1500.161621,1108.622070 
	   C1501.923462,1108.864014 1503.866333,1109.717407 1505.918335,1111.257812 
	   C1504.132446,1111.041016 1502.237671,1110.137329 1500.161621,1108.622070 
   z"/>
   <path fill="#BABABE" opacity="1.000000" stroke="none" 
	   d="
   M684.562134,982.523071 
	   C687.986694,982.495483 688.044250,984.061279 685.432312,986.229126 
	   C684.709656,985.258240 684.528137,984.260986 684.562134,982.523071 
   z"/>
   <path fill="#797985" opacity="1.000000" stroke="none" 
	   d="
   M1604.340210,1551.708740 
	   C1603.719849,1550.689941 1603.857300,1549.969360 1603.934204,1548.693604 
	   C1604.581299,1547.840210 1605.288940,1547.542114 1606.570679,1547.309814 
	   C1607.348877,1548.331177 1607.553101,1549.286865 1608.020264,1550.931152 
	   C1607.221436,1551.748779 1606.159668,1551.877930 1604.340210,1551.708740 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1639.892578,1232.690063 
	   C1641.024292,1233.243042 1642.221191,1234.386230 1643.180542,1236.222046 
	   C1641.947876,1235.703247 1640.952881,1234.491699 1639.892578,1232.690063 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1030.206787,915.337158 
	   C1030.649048,912.893433 1031.732666,911.255676 1033.883423,914.938782 
	   C1032.914795,915.539001 1031.820068,915.533936 1030.206787,915.337158 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1515.505615,1119.535645 
	   C1516.654907,1119.611694 1518.199951,1120.120972 1519.858154,1121.342529 
	   C1518.614624,1121.359375 1517.258057,1120.664062 1515.505615,1119.535645 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M1010.571472,910.088806 
	   C1011.817383,907.795776 1013.034729,908.298828 1013.938538,911.139160 
	   C1013.028015,911.259460 1012.050354,910.829285 1010.571472,910.088806 
   z"/>
   <path fill="#B6B6BE" opacity="1.000000" stroke="none" 
	   d="
   M784.461121,1401.463379 
	   C784.759216,1397.978394 786.209839,1397.827026 788.108154,1401.107422 
	   C787.138611,1401.687500 786.148621,1401.710571 784.461121,1401.463379 
   z"/>
   <path fill="#F8F8F9" opacity="1.000000" stroke="none" 
	   d="
   M998.233704,908.770630 
	   C999.083313,906.306458 1000.352905,905.728577 1001.904297,908.609436 
	   C1000.872314,909.179749 999.723267,909.213989 998.233704,908.770630 
   z"/>
   <path fill="#BABABE" opacity="1.000000" stroke="none" 
	   d="
   M680.305908,1356.552246 
	   C684.101379,1356.352905 684.392639,1357.797363 681.176636,1360.130371 
	   C680.427734,1359.211914 680.248779,1358.239746 680.305908,1356.552246 
   z"/>
   <path fill="#B6B6BE" opacity="1.000000" stroke="none" 
	   d="
   M700.492310,1399.500977 
	   C700.649353,1396.268433 701.977295,1395.168213 704.256348,1398.725342 
	   C703.221497,1399.464844 702.217834,1399.618652 700.492310,1399.500977 
   z"/>
   <path fill="#BABABE" opacity="1.000000" stroke="none" 
	   d="
   M670.465820,1380.919189 
	   C671.671570,1385.107178 671.612122,1385.179932 668.023193,1383.554565 
	   C668.566345,1382.228027 669.270813,1381.601440 670.465820,1380.919189 
   z"/>
   <path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	   d="
   M444.059723,880.835388 
	   C444.514038,881.855164 444.732117,883.584961 444.484161,885.656860 
	   C443.953217,884.514465 443.888367,883.029968 444.059723,880.835388 
   z"/>
   <path fill="#797985" opacity="1.000000" stroke="none" 
	   d="
   M1613.592773,1546.150146 
	   C1613.014771,1546.717041 1611.848999,1547.196655 1610.147339,1547.216553 
	   C1610.742554,1546.525635 1611.873779,1546.294189 1613.592773,1546.150146 
   z"/>
   <path fill="#030303" opacity="1.000000" stroke="none" 
	   d="
   M831.453918,1371.790161 
	   C814.567932,1372.191528 799.076904,1370.833130 784.001343,1364.395874 
	   C771.625549,1359.111450 764.943909,1350.864258 764.194336,1337.581909 
	   C763.447876,1324.353149 762.010315,1311.128052 761.987732,1297.899536 
	   C761.804932,1190.907837 761.883301,1083.915894 761.884277,976.924011 
	   C761.884399,958.868164 763.224976,957.362488 781.062866,957.964844 
	   C789.273193,958.242188 797.775452,958.248840 805.581726,960.412170 
	   C823.170410,965.286743 836.378540,976.543396 845.944031,992.036377 
	   C859.416077,1013.856750 864.040283,1037.932739 862.173401,1063.055664 
	   C861.333557,1074.357910 859.552063,1085.855103 854.058533,1096.381958 
	   C842.983093,1117.604736 826.487976,1129.335327 801.840820,1128.666382 
	   C794.322998,1128.462280 786.697144,1129.460083 779.248596,1130.684937 
	   C775.673218,1131.272827 772.395386,1133.670898 769.302795,1135.101196 
	   C770.323059,1142.455688 775.500061,1144.135010 779.733093,1145.922119 
	   C790.925842,1150.647583 802.192871,1155.427246 813.827209,1158.827271 
	   C835.987488,1165.303345 853.122864,1178.756470 867.794373,1195.780762 
	   C883.202637,1213.660034 891.957825,1234.965576 897.394165,1257.623535 
	   C899.695251,1267.214355 900.869995,1277.119019 901.956543,1286.947388 
	   C903.550964,1301.369995 900.349243,1315.507324 895.269104,1328.594238 
	   C885.986938,1352.505737 867.739563,1366.540283 842.135193,1370.491943 
	   C838.894043,1370.992065 835.632446,1371.360229 831.453918,1371.790161 
   z"/>
   <path fill="#A8A6B7" opacity="1.000000" stroke="none" 
	   d="
   M687.757751,1272.845947 
	   C687.097595,1270.910889 686.081238,1268.067139 686.078064,1265.222290 
	   C685.991821,1187.933105 686.004761,1110.643799 686.034790,1033.354492 
	   C686.035583,1031.383057 686.416138,1029.411865 687.147461,1026.837646 
	   C687.766724,1033.222290 687.937866,1040.209839 687.937317,1047.197510 
	   C687.931763,1122.110596 687.899048,1197.023560 687.757751,1272.845947 
   z"/>
   <path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	   d="
   M540.257751,1105.709595 
	   C541.385071,1106.497070 542.338684,1107.485474 543.292297,1108.473877 
	   C542.295654,1108.070923 541.299011,1107.667969 540.018127,1106.787354 
	   C539.733948,1106.309570 540.084106,1105.910522 540.257751,1105.709595 
   z"/>
   </svg>
		
	
		
    )
}
export default LogoSVG;