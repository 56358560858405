import React from "react";
import '../styles/navbar.css'
import '../styles/main.css';
import LogoSVG from "./LogoSVG";
import BurgerMenu from "./BurgerMenu";

const Navbar = () =>{


return(
    <div id="navbar" className="main-navbar">
        
        <ul className="main-navbar-nav center flex">
            
            <li id="logo" className="nav flex">
               
                <LogoSVG/>
                <div className="flex-column">
                <h3 className="no-margin">Bleu Willow</h3>
                <h3 className="no-margin">Advisors</h3>
                </div>
                
            </li>
    
            <li id="nav-links" className="flex nav-group">
            
                <a className="nav" href="#services">Services</a>
                <a className="nav" href="#about">About</a>
                <a className="nav" href="#contact">Contact</a>
            </li>
            <BurgerMenu/>
            
        </ul>
    </div>
)

}
export default Navbar