import React from "react";
import Banner from "./Banner";
import Navbar from "./Navbar";
import ServiceSection from "./ServiceSection";
import AboutSection from "./AboutSection";
import IndustriesSection from "./IndustriesSection";
import ContactSection from "./ContactSection";



const HomePage = () =>{
    const handleScroll = (e) =>{
        console.log(e.target)
    }
    return(
        <main onScroll={handleScroll}>
            <Navbar/>
            <Banner/>
            <IndustriesSection/>
            <ServiceSection/>
            
            <AboutSection/>
          
         
            <ContactSection/>
        </main>
    )
}

export default HomePage