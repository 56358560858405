import React,{useState, useEffect} from "react";
import "../styles/main.css"
import "../styles/StatsSection.css"
const StatsSection = () =>{
    const [capital, setCapital] = useState(0);

    useEffect(()=>{
        addOne()
    },[])


    const addOne = () =>{
        
        let count = 0;
        const myInterval = setInterval(()=>{
           
            if(count === 849){
                clearInterval(myInterval)
            }
            count += 1
            setCapital(capital=>capital+1)
         
        },50)
     
        
       
    }


    return(
       
                <>
         
                <h2 className="">Capital Raised: ${capital}M</h2>
                </>
    )
}

export default StatsSection;