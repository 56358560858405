import React from "react";
import '../styles/main.css';
import '../styles/About.css'


const AboutSection = () =>{
    return(
        <section className="flex-column " id="about">
        <div className="overlay"></div>
        <div id="content" className="flex-column">

                <div id="content-divider">
                <span></span>
            </div>
                    <h1 className="">About Us</h1>
                    <p>
                    We are an experienced financial advisory firm that connects our clients with financing opportunities to
fulfill their capital needs. We leverage our vast network and deep understanding of raising capital to
determine and deliver the best funding source for each client.
                    </p>
                    <p>
                    We have a deep understanding of the financial services industry that spans sectors and allows us the
ability to tap into a diverse network of global partners.
                    </p>
                    <p>
                    We bring our clients customized financial solutions to help them achieve and exceed their business
development and revenue goals in high-pressure environments.
                    </p>
                </div>            
      
    </section>
    )
}

export default AboutSection;