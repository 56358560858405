import React from "react";
import '../styles/main.css'
import '../styles/BurgerMenu.css'
const BurgerMenu = () =>{
    return(
        <li role="navigation" id="burger-menu">
  <div id="menuToggle" className="">

    <input type="checkbox" />

    <span className="bg-navy"></span>
    <span className="bg-navy"></span>
    <span className="bg-navy"></span>
    

    <ul id="menu" className="bg-navy">

      <a href="#services"><li>Services</li></a>
      <a href="#about"><li>About</li></a>

      <a href="#contact"><li>Contact</li></a>
      
    </ul>
  </div>
</li>
    )
}

export default BurgerMenu