import React from "react";
import '../styles/banner.css';
import '../styles/main.css'

import video from '../assets/videos/banner_vid.mp4'
const Banner = () =>{
    return(
        <div id="banner" className="flex-column">
        <div className="overlay"></div>
      
        <video muted autoPlay loop playsInline id="myVideo">
            <source src={video} type="video/mp4" />
        </video>
        <div id="banner-content" className="content">
            <div id="content-divider">
                <span></span>
            </div>
            <h1 id="action-text" className="header">Unlock the potential of your business with expert financial consulting and personalized investment strategies.</h1>
            <p id="action-text" className="">Explore your funding options today!</p>
    
            {/* <!-- <p id="action-text">Let Us Guide You Towards Prosperity and Success.</p> --> */}
            <button id="action-text" className="button-6"><a className="button" href="#contact">Contact Us</a></button>
        </div>
    </div>
    )
}
export default Banner;