import React, { useState } from "react";
import '../styles/main.css'
import '../styles/Industry.css'
import tech_img from '../assets/technology.jpg';
import comm_estate from '../assets/commercial_real_estate.jpg';
import gold_img from '../assets/gold.jpg';
import oil_img from '../assets/oil.jpg';
import StatsSection from './StatsSection';
import energy_img from '../assets/energy.jpg';
import ipo_img from '../assets/wallstreet-sophie-backes-UMfGoM67w48-unsplash.jpg';
import logistic_img from '../assets/logistic-dominik-luckmann-4aOhA4ptIY4-unsplash.jpg';
const IndustriesSection = () =>{
    const [active, setActive] = useState(0)
    const panels = [
        {
            header:'Commercial Real Estate',
            image:comm_estate
        },
        {
            header:'Gold Monetization',
            image:gold_img
        },
        {
            header:'Oil & Gas',
            image:oil_img
        },
        {   
            header:'Technology',
            image:tech_img
        },
        
        {
            header:'Energy',
            image:energy_img
        },
        {
            header:'IPOs',
            image:ipo_img
        },
        {
            header:'Logistics',
            image:logistic_img
        }

    ]


    return(
        <section id="experience" className="flex-column bg-navy">
            <div id="content" className="flex ">
                <div id="card">
                <div id="content-divider">
                        <span></span>
                    </div>
          
                        <h1 className="">Industry Experience</h1>
                    <div id="card-content" className="flex-column">

                        <p>Our strategy focuses on being industry agnostic <br/>in diverse markets to maximize opportunities.</p>
                        <div className="accordian">
                        {panels.map((panel, index)=>(
                            <article key={index} index={index} className={active === index ? 'active':''} onClick={()=>{
                                setActive(index)
                            }} >
                                <div id="overlay-2"></div>
                                <img loading="lazy" src={panel.image} alt="panel"/>
                                <h4>{panel.header}</h4>
                            </article>
                        ))}
                         </div>
                         <StatsSection/>
                        </div>
                </div>
                <hr id="industry-hr"/>
                <div id="card" >
                <div id="content-divider">
                        <span></span>
                    </div>
                    <div id='card-content'>
                        
                    
                
                    <div id="quote" className="blockquote blockquote--quoted ">
                        <p className="blockquote__text">    
                        In these everchanging times
                        the goal is to be ahead of the curve
                        to anticipate market changes
                        and manage growth and risk
                        effectively for your business.</p>
                        </div>
                    </div>
                </div>

             
                        
             
            </div>
           
        </section>
    )
}

export default IndustriesSection;